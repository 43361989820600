@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', serif;
  h1 {
    text-transform: capitalize;
  }
  a {
    text-decoration: none;
  }

  // header
  .section-header {
    align-items: center;
    margin: -1.5em 0 2em 0;
    font-size: 1em;
    .header-text {
      text-transform: uppercase;
    }
    .hr-line {
      padding: 0 14em;
    }
    .header-line {
      background: var(--tangerine);
      display: block;
      margin: auto;
      width: 80px;
      border: 0.2em solid var(--tangerine);
      border-radius: 5px;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  section {
    padding: 4em 3em;
    text-align: center;
  }
}
body {
  background: #f2f2f2;
  margin: 0;
}
:root {
  --mainWhite: #ffffff;
  --mainBlack: #000000;
  --primaryColor: #141413;
  --secondaryColor: #fd6e00;
  --thirdColor: #108590;
  --tangerine: #fd9004;
  --grey: #6c6d6e;
}
// BUTTON//
.button {
  background: var(--thirdColor);
  text-transform: capitalize;
  color: var(--mainWhite);
  padding: 0.5em 0.8em;
  margin: 2em;
  border: none;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5em;
  font-weight: bold;

  &:hover {
    background: var(--secondaryColor);
    color: var(--mainBlack);
    border: none;
  }
}

// Start NAVBAR//
.navbar {
  background: var(--mainWhite);
  position: fixed;
  width: 100%;
  top: 0;

  .container-nav {
    padding: 0.8em 0.8em 0 0.8em;
    display: flex;
    place-content: space-between;
  }
  .image-logo {
    padding-left: 5em;
    height: 2em;
    width: 7.5em;
  }

  .menu-bars {
    cursor: pointer;
    color: var(--mainBlack);
    float: right;
    height: 1.5em;
    width: 1.5em;
    margin: 0.2em;

    &:hover {
      color: var(--secondaryColor);
    }
  }
  .menu-times {
    cursor: pointer;
    color: var(--mainBlack);
    float: right;
    height: 1.5em;
    width: 1.5em;
    margin: 0.6em;

    &:hover {
      color: var(--secondaryColor);
    }
  }

  nav {
    background: var(--thirdColor);
    position: fixed;
    z-index: 999;
    width: 74%;
    right: -100%;
    top: 0;
    height: 100vh;
    padding: 0.5em;
    transition: 850ms;

    ul.links {
      margin-top: 3em;
    }
    li {
      list-style-type: none;
      padding: 1em;
      cursor: pointer;
    }
    .home-link,
    .menu-link {
      color: var(--mainWhite);
      text-decoration: none;
      display: block;
      font-size: 1.3em;
      text-align: right;
      transition: 0.5s;

      &:hover {
        font-weight: bold;
        color: var(--mainBlack);
        border-radius: 4px;
        padding-right: 7em;
        transition: 0.5s;
        background: var(--tangerine);
      }
    }
  }
  .menu.active {
    transition: 350ms;
    right: 0;
  }
  .dj-ziza-btn {
    float: right;
    margin-right: -1.5em;
  }
}
@media only screen and (min-width: 768px) {
  .menu-times,
  .menu-bars {
    display: none;
  }
  .container-nav {
    display: grid;
    grid-template-columns: 100px auto;
    padding: 0;
  }
  .navbar .menu {
    display: flex;
    justify-content: space-between;
    background: none;
    position: inherit;
    height: 1em;
    width: 100%;
    padding: 0;
    align-items: center;
    ul {
      display: flex;
    }
    .links {
      margin: 1em;
      padding-top: 2em;
      margin-top: 3em;
    }

    li {
      text-transform: capitalize;
      padding: 0.5em;
    }

    .home-link,
    .menu-link {
      color: black;
      margin-bottom: 2em;
      &:hover {
        padding: 0 0.6em;
        color: var(--mainWhite);
      }
    }
    .dj-ziza-btn {
      float: right;
      margin-top: 0.8em;
    }
  }
}

// End NAVBAR

//start of landing page
.main-page {
  align-items: center;
  .left-column {
    .landing-text {
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.3em;
      margin-top: 0.8em;
    }
    .landing-paragraph {
      margin-top: 0.8em;
    }
    .landing-image {
      width: 70%;
    }
  }
  .landing-image {
    width: 85%;
    border-radius: 50px 150px;
    margin-top: 2em;
  }
}

// end of landing page

//Card
.card {
  padding: 0.5em;
  text-decoration: none;
  background: var(--mainWhite);
  margin: 1em;
  padding: 1em;
  border: 0.5em;
  border-radius: 0.5em;
  .image-avatar {
    width: 40%;
    border-radius: 0.5em;
  }
  .card-service,
  .card-message {
    margin-bottom: 0.5em;
    color: var(--mainBlack);
  }

  &:hover {
    cursor: pointer;
    transition: background-color 0.5s ease;
    background-color: var(--secondaryColor);
  }
  &:visited {
    cursor: pointer;
    transition: background-color 0.5s ease;
    background-color: var(--secondaryColor);
  }
}
// end of card

// start of services
section.service-page {
  background: var(--thirdColor);

  .service-text {
    margin-bottom: 1.5em;
  }
}
// end of services
// start of contact
section.contact {
  background: var(--mainWhite);
  .chat-picture img {
    width: 80%;
  }
  label {
    display: block;
    font-size: 1.2em;
    margin-bottom: 0.5em;
  }
  input,
  textarea {
    width: 100%;
    padding: 0.8em;
    margin-bottom: 1em;
    border-radius: 0.3em;
    border: 1px solid grey;
    box-sizing: border-box;
  }
}
// end of contact

// start of footer
.footer {
  background: var(--grey);
  color: white;
  text-align: center;
  padding: 0.5em;

  .text-footer {
    margin: 0.5em;
  }
  .footer-credit {
    text-decoration: none;
    color: white;
  }
}
@media only screen and (min-width: 850px) {
  .container {
    margin: 0 auto;
  }
  section {
    padding: 10em 3em;
  }

  .main-page .container {
    display: flex;
    justify-content: space-between;
    text-align: left;
  }
  .left-column {
    margin: 3em 3em 0 5em;
  }
  .home-image {
    width: 160%;
    margin-bottom: 3em;
  }
  .left-column {
    margin-top: 5em;
  }
  .services-cards {
    display: flex;
  }
  section.contact {
    .container {
      text-align: center;
      margin: em;
      display: flex;
      justify-content: center;

      .chat-picture {
        width: 45%;
        height: 50%;
      }
      .contact-form {
        margin-top: 3em;
        width: 30%;
      }
    }
  }
}
@media only screen and (min-width: 180px) {
  .home-image {
    width: 100%;
    margin-bottom: 3em;
  }
}
// // start of djziza
// .dj-ziza {
//   .container {
//     margin-top: -2em;
//   }
//   .dj-logo {
//     width: 40%;
//   }
//   .dj-header {
//     padding: 0.5em;
//     margin-top: 4em;
//   }
//   .dj-links {
//     width: 100%;
//     .spotify,
//     .mixcloud {
//       display: inline;
//       width: 8%;
//       padding: 0em;
//       margin: 0.8em;
//       cursor: pointer;
//     }
//     .listen-links {
//       font-size: 1em;
//     }
//   }
// }

// .dj-form {
//   padding: 1.5em;
//   label {
//     display: block;
//     font-size: 1.2em;
//     margin-bottom: 0.5em;
//   }
//   input,
//   textarea {
//     width: 100%;
//     padding: 0.8em;
//     margin-bottom: 1em;
//     border-radius: 0.8em;
//     border: 1px solid var(--grey);
//     box-sizing: border-box;
//   }
// }
// // end of djziza
